import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage/session"; // defaults to localStorage for web
import { persistStore, persistReducer } from 'redux-persist'
import MotoformDataReducer from "./reducers/MotoformDataReducerSlice";
import SocketReducer from "./reducers/socketReducer";
import GroupMedicalReducer from "./reducers/GroupMedicalDataReducerSlice";
import YachtReducer from "./reducers/YachtDataReducerSlice";
import lobReducer from './reducers/lobSlice'; // Import your slice reducer
import Authreducer from "./reducers/Authreducer"
import { ServiceApi } from "./reducers/ServiceApi";
const lobPersistConfig = {
  key: "lob",
  storage,
};
const authPersistConfig = {
  key: "Authreducer",
  storage,
};

const store = configureStore({
  reducer: {
    lob: persistReducer(lobPersistConfig, lobReducer),  // Add the LOB reducer here
    Authreducer: persistReducer(authPersistConfig, Authreducer),
    MotoformDataReducer,
    SocketReducer,
    GroupMedicalReducer,
    YachtReducer,
    [ServiceApi.reducerPath]: ServiceApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(ServiceApi.middleware,),
});
const persistor = persistStore(store);
export { store, persistor }
