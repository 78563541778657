import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    token: '',
    APiToken: '',
};
const AuthSlice = createSlice({
    name: 'Auth',
    initialState,
    reducers: {
        setAuth: (state, action) => {
            console.log("token comes", action.payload)
            // Simply set the Auth state in the reducer
            state.token = action.payload.token;
        },
        setApiTOken: (state, action) => {
            console.log("token comes", action.payload)
            // Simply set the Auth state in the reducer
            state.APiToken = action.payload;
        },

    },
});

// Export actions
export const { setAuth, setApiTOken } = AuthSlice.actions;

// Export reducer
export default AuthSlice.reducer;
