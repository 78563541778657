import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setApiTOken } from './Authreducer';

const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_DEV}`,
    prepareHeaders: (headers, { getState }) => {
        const token = getState().Authreducer.APiToken;
        !!process.env.REACT_APP_UserID && headers.set('UserID', process.env.REACT_APP_UserID);
        !!token && headers.set('Token', token);
        return headers;
    },
});

const baseQueryWithTokenRefresh = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    console.log("result??", result)
    // Check if the error is specifically a token-related error (Code 10002)
    if (result.data && result.data.data?.Code === '1002') {
        try {
            // Attempt to refresh the token
            const refreshResult = await baseQuery('tpl/motor_a/lt', api, extraOptions);

            if (refreshResult?.data?.data?.Token) {
                console.log("refreshResult", refreshResult)
                // Update the token in the Redux store
                api.dispatch(setApiTOken(refreshResult?.data?.data?.Token));

                // Retry the original request with the new token
                result = await baseQuery(args, api, extraOptions);
            } else {
                // If token refresh fails, potentially log out the user
                api.dispatch(setApiTOken(""));
            }
        } catch (refreshError) {
            // Handle refresh token failure

        }
    }

    return result;
};

export const ServiceApi = createApi({
    reducerPath: 'CategoryApi',
    baseQuery: baseQueryWithTokenRefresh,
    endpoints: (builder) => ({
        Logintoken: builder.query({
            query: () => `tpl/motor_a/lt`,
        }),
        CreateQuotation: builder.mutation({
            query: (data) => ({
                url: 'tpl/motor_a/cq',
                method: 'POST',
                body: {
                    Data: data,
                },
            }),
        }),
    }),
});

export const { useLogintokenQuery } = ServiceApi;